import React from 'react';
import ReactDOM from 'react-dom';
import {LicenseManager} from 'ag-grid-enterprise';
import App from './App';
import * as serviceWorker from './serviceWorker';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);
ReactDOM.render(
    <App />,
    // eslint-disable-next-line no-undef
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
